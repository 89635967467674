export const sampleData = [
  {
    "name":"You X Scrye",
    "id":0,
    "description":"You X Scrye welcomes YOU into the Metaverse.\n\nYour likeness, owned and controlled by YOU.\n\nScrye: connecting the metaverse to the universe.",
    "image":"https://ipfs.chainsafe.io/ipfs/QmUy7H7S4A5pxgcMM2vaWWqkMBQM8hTEtq7v1vTuDYD4fx",
    "edition":1,
    "gltf":"https://ipfs.chainsafe.io/ipfs/QmUy7H7S4A5pxgcMM2vaWWqkMBQM8hTEtq7v1vTuDYD4fx"
  },
  {
    "name":"You X Scrye",
    "id":30,
    "description":"You X Scrye welcomes YOU into the Metaverse.\n\nYour likeness, owned and controlled by YOU.\n\nScrye: connecting the metaverse to the universe.",
    "image":"https://ipfs.chainsafe.io/ipfs/QmRk9mgykW7zPPotq59W9iv3NZBQx2NqVvHVWpdP3F3gsG",
    "edition":1,
    "gltf":"https://ipfs.chainsafe.io/ipfs/QmRk9mgykW7zPPotq59W9iv3NZBQx2NqVvHVWpdP3F3gsG"
  },
  {
    "name":"You X Scrye",
    "id":40,
    "description":"You X Scrye welcomes YOU into the Metaverse.\n\nYour likeness, owned and controlled by YOU.\n\nScrye: connecting the metaverse to the universe.",
    "image": "https://ipfs.chainsafe.io/ipfs/QmcdsVAjS3AYcGTJaiaJo1xaZ5wcjkymEGbkimW4PC2sVz",
    "edition":1,
    "gltf":	"https://ipfs.chainsafe.io/ipfs/QmcdsVAjS3AYcGTJaiaJo1xaZ5wcjkymEGbkimW4PC2sVz"
  },
  {
    "name":"You X Scrye",
    "id":50,
    "description":"You X Scrye welcomes YOU into the Metaverse.\n\nYour likeness, owned and controlled by YOU.\n\nScrye: connecting the metaverse to the universe.",
    "image":"https://ipfs.chainsafe.io/ipfs/QmS4AFfhP4cepoR69ZRFeLPBNAnrCTTjE65L7RNgMjJc9X",
    "edition":1,
    "gltf":"https://ipfs.chainsafe.io/ipfs/QmS4AFfhP4cepoR69ZRFeLPBNAnrCTTjE65L7RNgMjJc9X"
  }
];