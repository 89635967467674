import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { device } from "../../utils";

import { Title, Section, Box, Text } from "../../components/Core";
import Web3ConnectButton from "../../components/you-web3-connect-button"
import AnimatedFace from "../../assets/image/logos/animated_logo_face_white.svg";
import FaceLogoSvg from "../../assets/image/svg/_scrye/youxscrye.svg";

const SectionStyled = styled(Section)`
  background-color: ${({ theme}) => theme.goatColors.primary  };
  z-index: 2; // If this goes > 2 it buries web3modal, freezing site.
`;

const ImgLeft = styled(Box)`
  position: relative;
  left: 0;

  @media ${device.md} {
    position: absolute;
    top: 100px;
    max-width: 100%;
    min-width: 100%;
    left: 0;
  }

  svg path {
    animation: faceroll 30s linear infinite;

    @keyframes faceroll {
      0% { stroke: #00bcc9; }
      32% { stroke: #00bcc9; }
      34% { stroke: #7000db; }
      65% { stroke: #7000db; }
      67% { stroke: #f000de; }
      98% { stroke: #f000de; }
      100% { stroke: #00bcc9; }
    }
  }
`;


const HeroTitle = styled(Title)`
  color: ${({ theme }) => theme.goatColors.title  };
  font-family: 'goat-roboto';
  font-weight: 300;
`;

const HeroSubtitle = styled.div`
  font-family: 'goat-roboto','roboto', sans-serif;
  font-weight: 300;
  font-size: 22px;
  color: #cdcdcd;
  background: ${({ theme }) => theme.goatColors.black  };
  padding: 10px 0 10px 20px;

  .subtitle-extra {
    font-family: 'goat-roboto','roboto', sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #0ff0ff;
    padding-top: 20px;
  }
`;

const HeroViewer = styled.div`
  
`;



const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">

        <Container>
          <Row className="justify-content-center align-items-start position-relative">    
            <Col md="7" className="order-md-2">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box py={[null, null, null, 5]}  pt={[4, null]}>

                  <HeroTitle variant="hero" color="light">
                    <FaceLogoSvg/>
                  </HeroTitle>

                  <HeroSubtitle mb={4}>
                    Connecting the universe with the metaverse. <br/>
                    <br/>
                    Scrye makes avatars interoperable between virtual worlds and the real world, enabling the connection of the metaverse and the universe.
                    <div className="subtitle-extra">You are 1 of 1</div>
                  </HeroSubtitle>


                  <HeroViewer className="d-flex flex-column align-items-center pt-3">
                    <Web3ConnectButton text="Connect & View Your Scan" />
                  </HeroViewer>
                </Box>
              </div>
            </Col>

            <Col md="5" className="order-md-1">
              <ImgLeft>
                <AnimatedFace
                  className="img-fluid"
                  data-aos="fade-left"
                  data-aos-duration="750"
                  data-aos-delay="1000"
                  data-aos-once="true"/>
              </ImgLeft>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
