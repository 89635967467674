import { randomNum } from "./helperFn";

export const phrases = [
  "You are the 1/1",
  "Metaverse, Ready",
  "Immortal You",
  "Digital You",
  "Blockchain Immortal",
  "You, On Chain",
  "Challenger No.1",
  "You Are The Merch",
  "Scan Hero",
  "Scan Lord",
  "Crypto Counterpart",
  "Legendary 1/1",
  "Ultimate Rarity",
  "Looks Rare",
  "Top .01% Rarity",
  "1 of 1"
];


export const getCatchPhrase = () => {
  const i = randomNum( 0, phrases.length-1 );
  const phrase = phrases[i];
  phrases.splice(i,1);
  return phrase;
}