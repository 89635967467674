import React from "react";
import PageWrapper from "../components/PageWrapper";

import Gallery from "../sections/you/Gallery";
import Hero from "../sections/you/YouHero";

const You = () => (
  <PageWrapper footerDark>
    <Hero />
    <Gallery showPhrases={true} />
  </PageWrapper>
);
export default You;