import React from "react";
import styled from "styled-components";


const ExplainWrap = styled.div`
  position: relative;
  width: 100%;
  color: #f9f8e3;
  font-family: futura;
  padding: 0 15px;
  margin-bottom: 40px;
  text-align: center;

  .gallery-title {
    font-size: 34px;
    text-align: center;
    font-weight: bold;
  }

  .gallery-body { 
    font-size: 16px;
  }
`;

const GalleryExplain = ({title = "LATEST MINTS"}) => {
  return (
    <ExplainWrap>
      <div className="gallery-title">{title}</div>
      <div className="gallery-body">
        ROTATE: Click/Tap and drag to rotate your model.<br/>
        PAN: Right Click/Two Finger drag to adjust camera.
      </div>
    </ExplainWrap>
  );
}

export default GalleryExplain;



// import React from "react";
// import { Link } from "gatsby";

// import LogoMain from "../../assets/image/logos/face.svg";

// const Logo = ({ white, height, className = "", ...rest }) => {
//   return (
//     <Link to="/" className={`${className}`} {...rest}>
      
//       {/* STATIC LOGO */}
//       {/* <img src={logoMain} alt="" /> */}
      
//       {/* SVG LOGO */}
//       <LogoMain />
//     </Link>
//   );
// };

// export default Logo;