import React, { Suspense } from "react";
import styled from "styled-components";
import { Title, Box, Text } from "../Core";
import { device } from "../../utils";
import { getCatchPhrase } from "../../utils/phrases";

// 2D Image Assets
import BrandLogo from "../../assets/image/svg/_scrye/youxscrye.svg";

// 3D Needs
import { Canvas, useLoader } from '@react-three/fiber'
import { Environment, OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { ResizeObserver } from '@juggle/resize-observer';

const CardWrap = styled.div`
  max-width: 100vw;
  padding: 0 15px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 70px;
  position: relative;

  @media ${device.sm} {
    padding: 0 30px;
  }

  @media ${device.lg} {
    padding: 0 15px;
    max-width: 50%;
  }

  &.fill-space {
    @media ${device.lg} {
      padding: 0 15px;
      max-width: 100%;
    }
  }
`;

const GalleryImg = styled(Box)`
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 400px;
  min-width: 350px;
  background: #222;

  @media ${device.lg} {
    min-height: 570px;
    min-width: 350px;
  }

  a {
    width: 100%;
    position: relative;
    display: block;
    top: 0;
    left: 0;
  }

  .brand-logo {
    width: 30%;
    max-width: 110px;
    position: absolute;
    bottom: 7px;
    right: 7px;
    padding: 5px 0 0 10px;
    border-top-left-radius: 10px;
    text-shadow: 3px 3px 3px #000;
  }
`;

const GalleryText = styled(Box)`
  padding: 10px 0 0;
  text-align: left;
  width: 100%;

  .gallery-card-title,
  .gallery-card-catchphrase,
  .gallery-card-body {
    color: ${({ theme }) => theme.goatColors.panelText};
    text-align:left;
  }

  .gallery-card-title {
    margin-bottom: -15px;
    font-size: 2.8rem;
    line-height: 2.8rem;
    text-transform: uppercase;

    @media ${device.md} {
      margin-bottom: -10px;
      font-size: 3.5rem;
      line-height: 2.8rem;
    }
  }

  .gallery-card-catchphrase {
    position: relative;
    left: 9px;

    @media ${device.sm} {
      left 13px;
    }
  }

  // EXTRA - body text, not shown on face for now
  .gallery-card-body {
    font-family: 'goat-roboto', 'roboto', 'futura';
    font-weight: 300;
    font-style: italic;
  }
`;


const GalleryCard = ({
  modelId,
  imgBrand,
  title,
  showCatchPhrase = false,
  link,
  fillSpace,
  description,
  ...rest
}) => {

  const Model = () => {
    const gltf = useLoader(GLTFLoader, link );
    return (
      <>
        <primitive object={gltf.scene} scale={3} position={[0,-3,0]} />
      </>
    );
  };

  return (
    <CardWrap id={`model-${modelId}`} className={`d-flex ${!!fillSpace ? 'fill-space': ''}`} {...rest}>
      
      <GalleryImg>
          <Canvas resize={{ polyfill: ResizeObserver }}>
            <Suspense fallback={null}>

              {/* LIGHTING - Texture Blackout without it */}
              <ambientLight intensity={0.75} />

              {/* 3D MODEL ITSELF */}
              <Model />

              {/* BACKGROUND TEXTURE */}
              {/* <Environment preset="sunset" background /> */}

              {/* PERSPECTIVE CAMERA ALTERATIONS */}
              {/* <PerspectiveCamera makeDefault fov={65} position={[0, 0, 8]}></PerspectiveCamera> */}

              {/* ORBIT CONTROLS */}
              <OrbitControls 
                autoRotate
                autoRotateSpeed={2}
                enableZoom={false}
                enablePan={true} />
            </Suspense>
          </Canvas>
        <BrandLogo className="brand-logo" />
      </GalleryImg>

      <GalleryText>
        <Title className="gallery-card-title">
          {title}
        </Title>

        { !!showCatchPhrase && 
          <Text className="gallery-card-catchphrase">
            {getCatchPhrase()}
          </Text>
        }

        <Text className="gallery-card-body">
          {description}
        </Text>
      </GalleryText>

    </CardWrap>
  );
}


export default GalleryCard;
