import React, { Suspense, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { randomNum } from "../../utils/helperFn";
import { Section } from "../../components/Core";
import GalleryCard from "../../components/GalleryCard/GalleryCard";
import GalleryExplain from "../../components/GalleryCard/GalleryExplain";
import GoatButton from "../../components/GoatButton";
import { sampleData } from "./sampleData";


const Gallery = (showPhrases) => {
  const [walletAddress, setWalletAddress] = useState('0x696969696969696969696');
  const content = [];

  // CONNECT - dummy function for reveal testing
  const connect = () => {
    setWalletAddress('0x696969696969696969696');
  };

  // SAMPLE - Generates sample stubs for UI layout
  if ( !!walletAddress ) {
    for(var i = 0; i < sampleData.length; i++) {
      content.push(
        <GalleryCard
          key={randomNum(0,999999999)}
          modelId={sampleData[i].id}
          title={`You #${sampleData[i].id}`}
          showCatchPhrase={true}
          link={sampleData[i].gltf}>
        </GalleryCard>
      )
    }
  } 
  else {
    content.push(
      <GoatButton onClick={connect} text="connect" />
    );
  }

  return (
    <>
      {/* <!-- Feature section --> */}
      <Section className="position-relative">
        <Container>
          <Row className="align-items-top justify-content-center">
            <GalleryExplain />
            {content}
          </Row>
        </Container>
      </Section>
    </>
  );
}
export default Gallery;
