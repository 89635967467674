export const isPreMinting = process.env.GATSBY_IS_MINTING !== 'true' && process.env.GATSBY_IS_SOLD_OUT !== 'true';

export const isMintingOpen = process.env.GATSBY_IS_MINTING === 'true' && process.env.GATSBY_IS_SOLD_OUT !== 'true';

export const isSoldOut = process.env.GATSBY_IS_SOLD_OUT?.toLowerCase() === 'true';

export function getMintingStatusText() {
  if (isPreMinting) {
    return 'Minting Soon';
  }
  if (isSoldOut) {
    return 'Sold Out';
  }
  if (isMintingOpen) {
    return 'Connect & Mint';
  }
}