import React, { useState } from 'react'
import styled from 'styled-components'
import GoatButton from './GoatButton';
import uuid from 'uuid'
import GalleryExplain from './GalleryCard/GalleryExplain';
import { usePageContext } from '../context/web3'
import { getMintingStatusText, isMintingOpen } from '../utils/getMintingStatus'
import GalleryCard from './GalleryCard';

const YouConnectWrap = styled.div`
  text-align: center;
  align-items: center;
`;

const StyledGoatButton = styled(GoatButton)`
  max-width: 270px;
`;

const WalletConnectedContent = styled.div`
  margin-top: 1rem;
  color: rgb(206, 206, 206) !important;
  font-weight: 300;
  font-family: 'goat-roboto';
  text-align: center;

  .address {
    color: #0ff0ff;
  }
`

/**
 * The workflow for You: (TLDR not a live minting workflow. Just wallet connect)
 * 1. User has a scan at event, and gives wallet address.
 * 2. Admin mints / matches scan to NFT to address.
 * 3. User logs in to view their scan on You site.
 * @param {*} param0
 * @returns
 */
const BatchMintButton = ({ fromHeader, text: defaultText }) => {
  const [btnText, setBtnText] = useState(defaultText)
  const { handleWalletConnectForYouDrop, isWalletConnected, address, youScanMetadata } = usePageContext();

  async function handleButtonClick() {
    if (!isMintingOpen) return;
    if (!isWalletConnected) {
      setBtnText('Connecting')
      try {
        await handleWalletConnectForYouDrop()
        return;
      } catch (err) {
        setBtnText(defaultText)
      }
    }
  }

  return (
    <YouConnectWrap className="d-flex flex-column" style={{paddingBottom: '24px'}}>
      <StyledGoatButton text={isWalletConnected ? 'Connected' : btnText} disabled={isWalletConnected} onClick={handleButtonClick} />
      {
        isWalletConnected && (
          <WalletConnectedContent>
            <div>Wallet ID: <i className="address">{address}</i></div>
            <br/>
            <GalleryExplain title="" />
            {
              youScanMetadata && (
                <GalleryCard
                  key={uuid.v4()}
                  modelId={address}
                  title={`YOU #${youScanMetadata.id}`}
                  showCatchPhrase={true}
                  description={youScanMetadata.description}
                  fillSpace={true}
                  link={youScanMetadata.gltf}
                />
              )
            }
          </WalletConnectedContent>
        )
      }
    </YouConnectWrap>
  )
}

export default BatchMintButton;